var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"body-container-card-fvl"}},[_c('BreadCrumbs',{attrs:{"items":_vm.itemsBreadCrumbs}}),_c('LoaderLinear'),_c('div',{attrs:{"id":"fvl-container-card-table"}},[_c('v-card',{staticClass:"fvl-card-searcher-table",attrs:{"elevation":"0"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-text-field',{staticClass:"mb-6 searcher-input-fvl elevation-1",attrs:{"label":"Buscador de productos","append-icon":"search","dense":"","filled":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.dataTableheaders,"items":this.products.products,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(
            _vm.user.permissions.indexOf(_vm.permissionsAll.product_update) >= 0 ||
              _vm.user.person.types_person.id === 3
          )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" edit ")]):_vm._e(),(
            _vm.user.permissions.indexOf(_vm.permissionsAll.product_delete) >= 0 ||
              _vm.user.person.types_person.id === 3
          )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item, 1)}}},[_vm._v(" delete ")]):_vm._e()]}}])})],1)],1),_c('SnackBar'),(
      _vm.user.permissions.indexOf(_vm.permissionsAll.product_create) >= 0 ||
        _vm.user.person.types_person.id === 3
    )?_c('ButtonAdd',{attrs:{"link":_vm.linkAdd}}):_vm._e(),_c('DialogConfirm',{on:{"action":_vm.deleteProduct}}),_c('Overlay')],1)}
var staticRenderFns = []

export { render, staticRenderFns }