<template lang="">
  <v-container
    v-if="
      user.permissions.indexOf(permissionsAll.product_update) >= 0 ||
        user.person.types_person.id === 3
    "
  >
    <BreadCrumbs :items="itemsBreadCrumbs" />
    <FormProduct @sendForm="sendForm" :actionForm="actionForm" />
  </v-container>
</template>
<script>
import { beginRequest, endRequest, itemsBreadCrumbs } from "../../helpers";
import { FormProduct } from "../../components/products";
import { BreadCrumbs } from "../../components/_commons";
import { mapState } from "vuex";
import permissions from "../../helpers/permissions";

export default {
  name: "UserCreate",
  components: {
    BreadCrumbs,
    FormProduct,
  },
  data() {
    return {
      itemsBreadCrumbs: itemsBreadCrumbs.productEdit,
      actionForm: "Edit",
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async sendForm(formData) {
      await beginRequest();
      const { snack, status } = await this.$store.dispatch(
        "updateProduct",
        formData
      );
      await endRequest(snack, status);
    },
  },
};
</script>
