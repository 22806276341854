<template lang="">
  <div>
    <LoaderLinear />
    <v-card elevation="0" class="pa-5 ma-2">
      <form 
        @submit.prevent="sendForm" 
        id="formBrand"
        enctype="multipart/form-data"
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="$v.description.$model"
              label="Nombre del Producto (*)"
              :error-messages="descriptionErrors"
              @blur="$v.description.$touch()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="$v.unit.$model"
              label="Unidad de Medida (*)"
              :error-messages="unitErrors"
              @blur="$v.unit.$touch()"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="$v.link.$model"
              :error-messages="linkErrors"
              @blur="$v.link.$touch()"
              label="Web del Producto (*)"
            >
            </v-text-field>
          </v-col>          
        </v-row>

        <SelectBrandsSubBrands @setSubBrandSelected="setSubBrandSelected"/>        
        <LoadImageMultiple ref="imageComponent"/>

        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Button
              :disabled="$v.$invalid"
              classes="mr-4 float-right fvl-btn green-primary"
              type="submit"
              name="Guardar"
            />
            <router-link :to="goToBack">
              <Button
                :disabled="false"
                classes="mr-4 float-right fvl-btn green-military"
                type="button"
                name="Volver"
              />
            </router-link>
          </v-col>
        </v-row>
        </v-row>
      </form>
      <Overlay />
    </v-card>
    <SnackBar />
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { LoaderLinear, SnackBar, Overlay, Button, LoadImageMultiple } from "../_commons";
import { beginRequest, endRequest, links } from "../../helpers";
import { SelectBrandsSubBrands } from '../brands'

export default {
  name: "FormProduct",
  components: {
    LoaderLinear,
    SnackBar,
    Overlay,
    Button,
    SelectBrandsSubBrands,
    LoadImageMultiple
  },
  props: ["actionForm"],
  mixins: [validationMixin],
  validations: {
    description: { required, minLength: minLength(3) },
    link: { required, minLength: minLength(6) },
    unit: { required, minLength: minLength(1) },
    subBrandSelected: { required, minLength: minLength(1) },
  },
  data() {
    return {
      goToBack: links.product.list,
      description: "",
      link: "",
      unit: "",
      subBrandSelected:null,
    };
  },
  methods: {
    sendForm() {
      let formData=new FormData();
      formData.append('description', this.description);   
         
      if (this.actionForm==='Edit') {
        formData.append('id_product', this.$route.params.idproduct);        
      }

      formData.append('link', this.link);
      formData.append('unit', this.unit);
      formData.append('sub_brands_id', this.subBrandSelected);
      if (this.documentsStore.documentsSelected.length===0) {
        formData.append(`doc_files`, null);
        formData.append(`id_files`, null);
        formData.append(`descriptions`, null);
      } else{
        for (let i = 0; i < this.documentsStore.documentsSelected.length; i++) {        
          const doc=this.documentsStore.documentsSelected[i];
          formData.append(`doc_files[${i}]`, doc.doc_file);
          formData.append(`id_files[${i}]`, doc.id);
          formData.append(`descriptions[${i}]`, doc.description);
        }
      }
      this.$emit("sendForm", formData);
    },
    setSubBrandSelected(){
      this.subBrandSelected=this.brands.idSubBrandSelected;      
    },    
    async resetFormMethod() {
      this.unit = null;
      this.description = null;
      this.link = null;
      await this.$store.dispatch("removeDocumentsSelectedAction");      
      await this.$store.dispatch("setIdSubBrandSelected", null); 
      this.setSubBrandSelected();
      this.$v.$reset();
    },        
  },
  computed: {
    ...mapState({
      products: (state) => state.products,
      documentsStore: (state) => state.documents,
      brands: (state) => state.brands,
    }),
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.minLength &&
        errors.push("La longitud minima es de 3 caracteres");
      !this.$v.description.required &&
        errors.push("La descripción es requerida");
      return errors;
    },
    linkErrors() {
      const errors = [];
      if (!this.$v.link.$dirty) return errors;
      !this.$v.link.minLength &&
        errors.push("La longitud minima es de 6 caracteres");
      !this.$v.link.required && errors.push("El enlace es requerido");
      return errors;
    },
    unitErrors() {
      const errors = [];
      if (!this.$v.unit.$dirty) return errors;
      !this.$v.unit.minLength &&
        errors.push("La longitud minima es de 1 caracter");
      !this.$v.unit.required &&
        errors.push("La unidad es requerida");
      return errors;
    },
  },
  async created() {
    //ELIMINO LOS DOCUMENTOS DEL STORE
    await this.$store.dispatch("removeDocumentsSelectedAction");    
    await this.$store.dispatch("setIdSubBrandSelected", null);     
    await beginRequest();
    await this.$store.dispatch("setBrandsAction");
    const { snack, status } = await this.$store.dispatch("setSubBrandsAction");    
    
    if (this.actionForm === "Edit") {
      const { snack, status } = await this.$store.dispatch("setProductSelectedAction", this.$route.params.idproduct);
      if (this.products.productSelected) {                
        const productsStore=[];
        this.products.productSelected.get_documents.forEach(doc => {
          const data={};
          data.doc_file = doc.link;
          data.link = `${process.env.VUE_APP_SERVER}${doc.link}`;
          data.id = doc.id;
          data.description = doc.description;
          productsStore.push(data);
        });
        await this.$store.dispatch("setDocumentsSelectedAction", productsStore);

        await this.$store.dispatch("setIdBrandSelected", this.products.productSelected.get_sub_brand.brands_id);
        await this.$store.dispatch("setIdSubBrandSelected",this.products.productSelected.sub_brands_id);    
        this.setSubBrandSelected();
        this.unit = this.products.productSelected.unit;
        this.description = this.products.productSelected.description;
        this.link = this.products.productSelected.link;
      }
      await endRequest(snack, status);    
      return
    }
    await endRequest(snack, status);    
    //VINCULA THIS.DOCUMENTS CON EL DOCUMENTS DEL COMPONENTE HIJO
    this.documents=this.$refs.imageComponent.documents;
  },
};
</script>
